<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0">Category</h4>
      </div>
      <div class="card-body billpayment-menu">
        <router-link
          v-if="
            categoryList[0]['name'] == `Mobile Recharge` ||
            categoryList[1]['name'] == `Mobile Recharge`
          "
          class="d-flex justify-content-between text-decoration-none text-dark"
          :to="{
            name: `mobile_recharge`,
            params: {
              category: `Mobile_Recharge`,
              id: this.$route.params.id,
            },
          }"
        >
          <h6 class="card-title">Mobile Recharge</h6>
          <b-icon-chevron-right></b-icon-chevron-right>
        </router-link>
        <router-link
          v-if="
            categoryList[1]['name'] == `Electricity Bill` ||
            categoryList[0]['name'] == `Electricity Bill`
          "
          class="d-flex justify-content-between text-decoration-none text-dark"
          :to="{
            name: `electricity-bill`,
            params: {
              category: `Electricity`,
              id: this.$route.params.id,
            },
          }"
        >
          <h6 class="card-title">Electricity Bill</h6>
          <b-icon-chevron-right></b-icon-chevron-right>
        </router-link>
        <router-link
          v-if="categoryList[2]['name'] == `DTH Payment`"
          class="card-title d-flex justify-content-between text-decoration-none text-dark"
          :to="{
            name: `dth-recharge`,
            params: {
              category: `DTH`,
              id: this.$route.params.id,
            },
          }"
        >
          <h6 class="card-title">{{ categoryList[2]["name"] }}</h6>
          <b-icon-chevron-right></b-icon-chevron-right>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "category",
  computed: {
    ...mapState({
      categoryList: (state) => state.billPayment.categoryList,
      marketList: (state) => state["home"].marketList,
    }),
  },
  async mounted() {
    const payload = this.$route.params.id; //"62d4fef39c487b63efee8f03"
    await this.$store.dispatch("billPayment/getCategory", payload);
  },
};
</script>
<style scoped>
.text-orange {
  color: #fe7b08;
}
.billpayment-menu .router-link-exact-active {
  color: #fe7b08 !important;
}
</style>
